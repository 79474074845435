import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout/Layout";
import Home from "../components/Home/Home";

const IndexPage: FunctionComponent = () => {
  const { t } = useTranslation(["site"]);

  return (
    <Layout title={t("site:title")} description={t("site:description")}>
      <Home />
    </Layout>
  );
};

export default IndexPage;
