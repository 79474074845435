import { BaseSyntheticEvent, FunctionComponent, useState } from "react";
import classNames from "classnames";
import {
  alert,
  alert_danger,
  alert_success,
  icon,
  services,
  title,
  text,
  txt,
} from "./ContactSection.module.scss";

interface ContactForm {
  name: HTMLInputElement;
  email: HTMLInputElement;
  phone: HTMLInputElement;
  website: HTMLInputElement;
  message: HTMLTextAreaElement;
}

interface ContactUsResponse {
  success: boolean;
}

type SubmitStatusType = "submitting" | "success" | "error" | "none";

const ContactSection: FunctionComponent = () => {
  const [submitStatus, setSubmitStatus] = useState<SubmitStatusType>("none");

  const handleSubmit = (event: BaseSyntheticEvent) => {
    event.preventDefault();

    if (!process.env.CONTACT_API_URL) {
      setSubmitStatus("error");
      return;
    }

    const { name, phone, email, message, website } =
      event.target as ContactForm;

    setSubmitStatus("submitting");

    fetch(process.env.CONTACT_API_URL, {
      method: "POST",
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "omit", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      // redirect: "follow", // manual, *folslow, error
      // referrer: "client", // no-referrer, *client
      body: JSON.stringify({
        name: name?.value ?? "",
        phone: phone?.value ?? "",
        email: email?.value ?? "",
        message: message?.value ?? "",
        website: website?.value ?? "",
      }),
    })
      .then((response) => response.json())
      .then((data: ContactUsResponse) => {
        if (data.success) {
          setSubmitStatus("success");
        } else {
          // eslint-disable-next-line no-console
          console.error("Send email error");
          setSubmitStatus("error");
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error:", error);
        setSubmitStatus("error");
      });
  };

  const handleSendMore = () => {
    const form = document.getElementById("contact-form") as HTMLFormElement;
    form?.reset();
    setSubmitStatus("none");
  };

  return (
    <>
      <div className="gray-color rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 md-mb-30">
              <div className="sec-title mb-40">
                <div className="sub-text style4-bg left">Let&apos;s Talk</div>
                <h2 className="title pb-20">Speak With Expert Engineers.</h2>
                <div className="desc">
                  We will help you to be a modern and High-Tech Company!
                </div>
              </div>
              <div className={classNames(services, "mb-25")}>
                <div className={icon}>
                  <img
                    src="/img/choose/icons/style2/1.png"
                    alt=""
                    loading="lazy"
                    width="55"
                    height="55"
                  />
                </div>
                <div className={text}>
                  <h3 className={title}>Email</h3>
                  <p className={txt}>
                    <a href="mailto:support@hypersoft.io">
                      support@hypersoft.io
                    </a>
                  </p>
                </div>
              </div>
              <div className={classNames(services, "mb-25")}>
                <div className={icon}>
                  <img
                    src="/img/choose/icons/style2/2.png"
                    alt=""
                    loading="lazy"
                    width="55"
                    height="55"
                  />
                </div>
                <div className={text}>
                  <h3 className={title}>Call Us</h3>
                  <p className={txt}> (+380) 503-810-107</p>
                </div>
              </div>
              <div className={services}>
                <div className={icon}>
                  <img
                    src="/img/choose/icons/style2/3.png"
                    alt=""
                    loading="lazy"
                    width="55"
                    height="55"
                  />
                </div>
                <div className={text}>
                  <h3 className={title}>Office Address</h3>
                  <p className={txt}>
                    6-а, Olesya Gonchara, BC-Neon City, 4th floor, Kherson,
                    Ukraine
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="rs-contact mod1">
                <div id="contact-us" className="contact-wrap">
                  <div className="content-part mb-25">
                    <h2 className="title mb-15">Schedule Appointment</h2>
                    <p className="desc">We here to help you</p>
                  </div>
                  <form id="contact-form" onSubmit={handleSubmit}>
                    <fieldset>
                      <div className="row">
                        <div className="col-lg-12 mb-15 col-md-6 col-sm-6">
                          <input
                            className="from-control"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name *"
                            required
                          />
                        </div>
                        <div className="col-lg-12 mb-15 col-md-6 col-sm-6">
                          <input
                            className="from-control"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="E-Mail *"
                            required
                          />
                        </div>
                        <div className="col-lg-12 mb-15 col-md-6 col-sm-6">
                          <input
                            className="from-control"
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Phone Number"
                          />
                        </div>
                        <div className="col-lg-12 mb-15 col-md-6 col-sm-6">
                          <input
                            className="from-control"
                            type="text"
                            id="website"
                            name="subject"
                            placeholder="Your Website"
                          />
                        </div>

                        <div className="col-lg-12 mb-25 col-md-12 col-sm-12">
                          <textarea
                            className="from-control"
                            id="message"
                            name="message"
                            placeholder="Your Message"
                          />
                        </div>
                      </div>

                      <div className="form-group mb-0">
                        {submitStatus === "error" && (
                          <div className={classNames(alert, alert_danger)}>
                            Error sending message
                          </div>
                        )}
                        {submitStatus !== "success" && (
                          <button
                            type="submit"
                            className="submit-btn orange-btn"
                            disabled={submitStatus === "submitting"}
                          >
                            {submitStatus === "submitting"
                              ? "Submitting..."
                              : "Submit Now"}
                          </button>
                        )}
                        {submitStatus === "success" && (
                          <div>
                            <div className={classNames(alert, alert_success)}>
                              The message was sent successfully
                            </div>
                            <button
                              type="button"
                              onClick={handleSendMore}
                              className="btnAsLink"
                            >
                              Send more?
                            </button>
                          </div>
                        )}
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-inner">
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
    </>
  );
};
export default ContactSection;
