import { FunctionComponent } from "react";
import AboutSection from "../AboutSection/AboutSection";
import AboutSkillSection from "../AboutSkillSection/AboutSkillSection";
import ChooseSection from "../ChooseSection/ChooseSection";
import ContactSection from "../ContactSection/ContactSection";
import ProjectSection from "../ProjectSection/ProjectSection";
import ServiceSection from "../ServiceSection/ServiceSection";
import TechStackSection from "../TechStackSection/TechStackSection";
import TestimonialSection from "../TestimonialSection/TestimonialSection";

const Home: FunctionComponent = () => (
  <>
    <TechStackSection />
    <AboutSection />
    <AboutSkillSection />
    <ServiceSection />
    <ChooseSection />
    <ContactSection />
    <ProjectSection />
    <TestimonialSection />
  </>
);
export default Home;
