/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent, useState } from "react";
import classNames from "classnames";
// import { box } from "./TestimonialSection.module.scss";

interface CustomerComment {
  id: number;
  name: string;
  designation: string;
  photo: string;
  text: string;
}

const comments: CustomerComment[] = [
  {
    id: 0,
    name: "Ivana",
    designation: "CEO of grocery stores chain",
    photo: "/img/customers/ivana.png",
    text: "My stores have a specific format, a significant flow of customers, and all purchases had to be paid exclusively through the cashier. Huge lines became the biggest problem. I was losing the loyalty of regular customers, customers started switching to competitors who already had automation. So I turned to Hyper Soft for help and they quickly installed and set up self-service complexes in my stores. Now customers can make the necessary purchases themselves. The programmers have also created a mobile application for the chain, through which you can order goods online. I am really happy: we managed to keep customers, they recommend our chain to their friends, the demand is growing. So I'm already opening new stores using automation from Hyper Soft. After all, if you have not digitalized yet, consider yourself gone from the market.",
  },
  {
    id: 1,
    name: "Oleg",
    designation: "Owner of a construction materials company",
    photo: "/img/customers/oleg.png",
    text: "Thanks to the software solution from HyperSoft, the efficiency of processes in my production has almost tripled! Previously, staff had to spend up to 30% of working time filling out documents manually. As a result, delivery of orders to retail chains was delayed, and I risked losing my regular customers. The software package, created by Hyper Soft engineers especially for us, solved the problem of manual circulation of documents. The process of registration and delivery of our goods has significantly accelerated, so the company was able to increase volumes without costs for logistics. The most valuable thing I got was the optimized time I now spend on other areas of business.",
  },
];

const TestimonialSection: FunctionComponent = () => {
  const [activeComment, setActiveComment] = useState(comments[0]);

  const nextComment = (): void => {
    const idx = comments.findIndex((item) => item.id === activeComment.id);

    setActiveComment(
      idx === -1 || idx === comments.length - 1
        ? comments[0]
        : comments[idx + 1]
    );
  };

  return (
    <div className="rs-testimonial main-home rs-rain-animate style4 gray-color modify1 md-fixing">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hidden-md">
            <div className="testi-image">
              <img
                src="/img/testimonial.png"
                alt=""
                loading="lazy"
                width="516"
                height="583"
              />
            </div>
          </div>
          <div className="col-lg-6 pl-50 md-pl-15">
            <div className="sec-title mb-50">
              <div className="sub-text style4-bg left testi">Testimonials</div>
              <h2 className="title pb-20">What Customer Saying</h2>
            </div>
            <div className="rs-carousel">
              <div id="comment" className="testi-item">
                <div className="author-desc">
                  <div className="desc">
                    <img
                      className="quote"
                      src="/img/quote.png"
                      alt=""
                      loading="lazy"
                      width="50"
                      height="50"
                    />
                    {activeComment.text}
                  </div>
                </div>
                <div className="testimonial-content">
                  <div className="author-img">
                    <img
                      src={activeComment.photo}
                      alt=""
                      loading="lazy"
                      width="90"
                      height="90"
                    />
                  </div>
                  <div className="author-part">
                    <a className="name" href="#">
                      {activeComment.name}
                    </a>
                    <span className="designation">
                      {activeComment.designation}
                    </span>
                  </div>
                </div>
              </div>

              <div className="text-right mt-45">
                <a
                  href="#comment"
                  className="readon next"
                  onClick={nextComment}
                >
                  Next
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-inner">
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  );
};
export default TestimonialSection;
