import { FunctionComponent } from "react";
import classNames from "classnames";
import {
  services,
  imagePart,
  animation,
  topShape,
  dance,
  dance2,
  bottomShape,
  icon,
  text,
  title,
  txt,
} from "./ChooseSection.module.scss";

const ChooseSection: FunctionComponent = () => (
  <div className="pt-120 pb-120 md-pt-70 md-pb-75">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 hidden-md">
          <div className={imagePart}>
            <img
              src="/img/choose/choose.png"
              alt=""
              loading="lazy"
              width="590"
              height="590"
            />
          </div>
          <div className={animation}>
            <div className={topShape}>
              <img
                className={dance}
                src="/img/choose/dotted-2.png"
                alt=""
                loading="lazy"
                width="200"
                height="91"
              />
            </div>
            <div className={bottomShape}>
              <img
                className={dance2}
                src="/img/choose/dotted-1.png"
                alt=""
                loading="lazy"
                width="120"
                height="114"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 pl-60 md-pl-15">
          <div className="sec-title mb-40">
            <div className="sub-text style4-bg left">Why Choose Us</div>
            <h2 className="title pb-20">
              We Create Result-Oriented Complex Solution
            </h2>
            <div className="desc">What we will do for your business</div>
          </div>
          <div className={classNames(services, "mb-25")}>
            <div className={icon}>
              <img
                src="/img/choose/icons/1.png"
                alt=""
                loading="lazy"
                width="55"
                height="52"
              />
            </div>
            <div className={text}>
              <h3 className={title}>Simple</h3>
              <p className={txt}>
                A simple and intuitive interface allows you to quickly implement
                the software into the company&apos;s day-to-day operations.
              </p>
            </div>
          </div>
          <div className={classNames(services, "mb-25")}>
            <div className={icon}>
              <img
                src="/img/choose/icons/2.png"
                alt=""
                loading="lazy"
                width="55"
                height="55"
              />
            </div>
            <div className={text}>
              <h3 className={title}>Good quality</h3>
              <p className={txt}>
                Software packages developed by our employees in international
                partnership have been successfully implemented by more than 150
                companies from Germany, Austria and Switzerland.
              </p>
            </div>
          </div>
          <div className={services}>
            <div className={icon}>
              <img
                src="/img/choose/icons/3.png"
                alt=""
                loading="lazy"
                width="55"
                height="65"
              />
            </div>
            <div className={text}>
              <h3 className={title}>Care</h3>
              <p className={txt}>
                We care about the safety of your commercial information.
                Two-factor authentication and strict confidentiality guarantee
                the security of our products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default ChooseSection;
