/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent } from "react";
import classNames from "classnames";
// import { box } from "./ProjectSection.module.scss";

const ProjectSection: FunctionComponent = () => (
  <div id="rs-blog" className="rs-blog pt-120 pb-120 md-pt-70 md-pb-80">
    <div className="container">
      <div className="sec-title2 text-center mb-30">
        <span className="sub-text">Projects</span>
        <h2 className="title testi-title">Our Recent Launched Projects</h2>
        <div className="desc">
          We&apos;ve been building creative tools together for over a decade and
          have a deep appreciation for software applications
        </div>
      </div>
      <div className="rs-blog">
        <div className="blog-item">
          <div className="image-wrap">
            <img
              src="/img/projects/miran.jpg"
              alt=""
              loading="lazy"
              width="340"
              height="340"
            />
            {/* <ul className="post-categories">
              <li>
                <a href="#">Web Application</a>
              </li>
            </ul> */}
          </div>
          <div className="blog-content">
            <h3 className="blog-title">
              Task Manager
              {/* <a href="#">Task Manager</a> */}
            </h3>
            <p className="desc">
              Stores, systematizes and controls the performance of tasks. It
              disciplines the team and speeds up communication at all levels.
            </p>
            {/* <div className="blog-button"><a href="https://miran.cloud" target="_blank">Learn More</a></div> */}
          </div>
        </div>

        <div className="blog-item">
          <div className="image-wrap">
            <img
              src="/img/projects/medic.jpg"
              alt=""
              loading="lazy"
              width="340"
              height="340"
            />
            {/* <ul className="post-categories">
              <li>
                <a href="#">Web Application</a>
              </li>
            </ul> */}
          </div>
          <div className="blog-content">
            <h3 className="blog-title">Medical Manager</h3>
            <p className="desc">
              Patient appointment and personal account. Analyzes and their
              results. Doctor&apos;s personal account and work schedule.
              Administration, billing and financial accounting.
            </p>
          </div>
        </div>

        <div className="blog-item">
          <div className="image-wrap">
            <img
              src="/img/projects/erp.jpg"
              alt=""
              loading="lazy"
              width="340"
              height="340"
            />
            {/* <ul className="post-categories">
              <li>
                <a href="#">Software Development</a>
              </li>
            </ul> */}
          </div>
          <div className="blog-content">
            <h3 className="blog-title">Business Automation</h3>
            <p className="desc">
              The complex solution included: Personnel accounting, Financial
              accounting, Warehouse, Billing, Automation for production
              processes.
            </p>
          </div>
        </div>

        <div className="blog-item">
          <div className="image-wrap">
            <img
              src="/img/projects/crm.jpg"
              alt=""
              loading="lazy"
              width="340"
              height="340"
            />
            {/* <ul className="post-categories">
              <li>
                <a href="#">Software Development</a>
              </li>
            </ul> */}
          </div>
          <div className="blog-content">
            <h3 className="blog-title">Customer Management</h3>
            <p className="desc">
              CRM system to be used in working with customers and clients of the
              company with high integration to internal company processes.
            </p>
          </div>
        </div>

        <div className="blog-item">
          <div className="image-wrap">
            <img
              src="/img/projects/call.jpg"
              alt=""
              loading="lazy"
              width="340"
              height="340"
            />

            {/* <ul className="post-categories">
              <li>
                <a href="#">Support</a>
              </li>
            </ul> */}
          </div>
          <div className="blog-content">
            <h3 className="blog-title">Call-Centre</h3>
            <p className="desc">
              To implement joint projects with our clients from Western Europe,
              the work of customer support center was specifically created and
              organized.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default ProjectSection;
