import { FunctionComponent, Fragment } from "react";
import classNames from "classnames";
import { box, stack_logo } from "./TechStackSection.module.scss";
import reactSvg from "../../../static/img/stack/react.svg";
import angularSvg from "../../../static/img/stack/angular.svg";
import nodejsSvg from "../../../static/img/stack/nodejs.svg";
import tsSvg from "../../../static/img/stack/ts.svg";
import jsSvg from "../../../static/img/stack/js.svg";
import graphqlSvg from "../../../static/img/stack/graphql.svg";
import awsSvg from "../../../static/img/stack/aws.svg";
import cloudSvg from "../../../static/img/stack/cloud.svg";
import sqlSvg from "../../../static/img/stack/sql.svg";
import pwaSvg from "../../../static/img/stack/pwa.svg";
import universalSvg from "../../../static/img/stack/universal.svg";
import dockerSvg from "../../../static/img/stack/docker.svg";
import materialSvg from "../../../static/img/stack/material.svg";
import i18nSvg from "../../../static/img/stack/i18n.svg";

const TechStackSection: FunctionComponent = () => (
  <>
    <div className={classNames(box, "pt-50 pb-50")}>
      <div className="container text-center">
        <div className={stack_logo}>
          <img src={reactSvg} alt="react" width="112" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={angularSvg} alt="angular" width="94" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={nodejsSvg} alt="node" width="163" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={tsSvg} alt="ts" width="100" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={jsSvg} alt="js" width="100" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={graphqlSvg} alt="graphql" width="100" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={awsSvg} alt="aws" width="167" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={cloudSvg} alt="cloud" width="100" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={sqlSvg} alt="sql" width="75" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={pwaSvg} alt="pwa" width="93" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={universalSvg} alt="universal" width="93" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={dockerSvg} alt="docker" width="119" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={materialSvg} alt="material" width="93" height="100" />
        </div>
        <div className={stack_logo}>
          <img src={i18nSvg} alt="i18n" width="93" height="100" />
        </div>
      </div>
    </div>
  </>
);
export default TechStackSection;
