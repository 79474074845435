import { FunctionComponent } from "react";
import classNames from "classnames";
import { box } from "./AboutSkillSection.module.scss";

const AboutSkillSection: FunctionComponent = () => {
  const companyYears = new Date().getFullYear() - 2011; // 09.11.2011

  return (
    <div className="rs-about style2 modify1 pt-120 pb-120 md-pt-70 md-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hidden-md">
            <div className="images">
              <img
                src="/img/about.png"
                alt=""
                loading="lazy"
                width="600"
                height="579"
              />
            </div>
          </div>
          <div className="col-lg-6 pl-60 md-pl-15">
            <div className="sec-title mb-30">
              <div className="sub-text style4-bg">About Us</div>
              <h2 className="title pb-20">
                We Are Increasing Business Success With Technology
              </h2>
              <div className="desc">
                Over {companyYears} years working in IT services developing
                software applications and mobile apps for clients all over the
                world.
              </div>
            </div>
            <div className="rs-skillbar">
              <span className="skillbar-title">Software Development</span>
              <div className="skillbar">
                <p
                  className="skillbar-bar orange-bg"
                  style={{ width: "90%" }}
                />
                <span className="skill-bar-percent">90%</span>
              </div>
              <span className="skillbar-title">Web Development</span>
              <div className="skillbar">
                <p className="skillbar-bar paste-bg" style={{ width: "85%" }} />
                <span className="skill-bar-percent">85%</span>
              </div>
              <span className="skillbar-title">Mobile Development</span>
              <div className="skillbar">
                <p className="skillbar-bar blue-bg" style={{ width: "75%" }} />
                <span className="skill-bar-percent">75%</span>
              </div>
              <span className="skillbar-title">Clould & Serverless</span>
              <div className="skillbar">
                <p className="skillbar-bar teal-bg" style={{ width: "90%" }} />
                <span className="skill-bar-percent">90%</span>
              </div>
              <span className="skillbar-title">Product Design</span>
              <div className="skillbar">
                <p className="skillbar-bar pink-bg" style={{ width: "80%" }} />
                <span className="skill-bar-percent">80%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutSkillSection;
