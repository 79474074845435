import { FunctionComponent } from "react";
import classNames from "classnames";
import { box } from "./AboutSection.module.scss";

const AboutSection: FunctionComponent = () => (
  <div className="rs-about pt-120 md-pt-70">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="sec-title mb-50">
            <div className="sub-text style4-bg">About Us</div>
            <h2 className="title pb-20">
              We Are Helping Brands to Reach Full Potential
            </h2>
            <div className="desc">
              We working in IT services developing software applications and
              mobile apps for clients all over the world.
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="rs-services style3 md-pt-50">
            <div className="container">
              <div className="row">
                <div className="col-md-6 pr-10 pt-40 sm-pt-0 sm-pr-0 sm-pl-0">
                  <div className="services-item mb-20">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="/img/services/style3/main-img/1.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                        <img
                          className="hover-img"
                          src="/img/services/style3/hover-img/1.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">High-quality Code</a>
                        </h3>
                      </div>
                      <div className="services-desc">
                        <p>
                          We develop software solutions of different levels of
                          complexity: websites, web applications, mobile
                          applications, front-end and back-end websites, admin
                          panels, software, integrated solutions for company
                          automation. We digitalize any business.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="services-item cyan-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="/img/services/style3/main-img/2.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                        <img
                          className="hover-img"
                          src="/img/services/style3/hover-img/2.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">Full-stack teams</a>
                        </h3>
                      </div>
                      <div className="services-desc">
                        <p>
                          We offer full range of services and work with
                          well-coordinated teams of specialists. An experienced
                          expert in his profile is responsible for each stage of
                          the project. This is how a digital product, in which
                          there are no weaknesses, is created.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 pl-10 sm-pr-0 sm-pl-0 sm-mt-20">
                  <div className="services-item gold-bg mb-20">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="/img/services/style3/main-img/3.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                        <img
                          className="hover-img"
                          src="/img/services/style3/hover-img/3.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">Agile Approach</a>
                        </h3>
                      </div>
                      <div className="services-desc">
                        <p>
                          Our software solutions are always elegant and easy to
                          operate. The main focus of the company’s work is the
                          client. Before starting cooperation, we will study the
                          specifics of your business and take into account the
                          smallest details. Even if you are not sure what
                          exactly you want to get, we will help - we will
                          analyze, offer and upgrade.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="services-item blue-bg">
                    <div className="services-icon">
                      <div className="image-part">
                        <img
                          className="main-img"
                          src="/img/services/style3/main-img/4.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                        <img
                          className="hover-img"
                          src="/img/services/style3/hover-img/4.png"
                          alt=""
                          loading="lazy"
                          width="128"
                          height="128"
                        />
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="services-text">
                        <h3 className="title">
                          <a href="web-development.html">Digital Solutions</a>
                        </h3>
                      </div>
                      <div className="services-desc">
                        <p>
                          We follow high standards, so in our work we use
                          innovative developments, explore the latest trends in
                          IT. Our final product is a set of software solutions,
                          service testing, training and customer support at all
                          stages.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default AboutSection;
