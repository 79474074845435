// extracted by mini-css-extract-plugin
export var services = "ChooseSection-module--services--30maR";
export var icon = "ChooseSection-module--icon--1rEP-";
export var text = "ChooseSection-module--text--OEkZ9";
export var title = "ChooseSection-module--title--1oKpO";
export var txt = "ChooseSection-module--txt--16bkZ";
export var animation = "ChooseSection-module--animation--3wtcb";
export var topShape = "ChooseSection-module--topShape--24a-Y";
export var bottomShape = "ChooseSection-module--bottomShape--3640V";
export var imagePart = "ChooseSection-module--imagePart--T5L-E";
export var dance = "ChooseSection-module--dance--1YNr7";
export var dance2 = "ChooseSection-module--dance2--3LOcE";