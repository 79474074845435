import { FunctionComponent } from "react";
import classNames from "classnames";
// import { box } from "./ServiceSection.module.scss";

const ServiceSection: FunctionComponent = () => (
  <div className="rs-services style3 rs-rain-animate gray-color pt-120 pb-120 md-pt-70 md-pb-80">
    <div className="container">
      <div className="row md-mb-60">
        <div className="col-lg-6 mb-60 md-mb-20">
          <div className="sec-title2 md-center">
            <span className="sub-text">Services</span>
            <h2 className="title testi-title">
              We Create Smart Software Solutions For Your Business
            </h2>
          </div>
        </div>
        <div className="col-lg-6 mb-60 md-mb-0">
          <div className="btn-part text-right mt-60 md-mt-0 md-center">
            <a className="readon started" href="#contact-us">
              Get Started
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-20">
          <div className="services-item">
            <div className="services-icon">
              <div className="image-part">
                <img
                  className="main-img"
                  src="/img/services/style2/main-img/1.png"
                  alt=""
                  loading="lazy"
                  width="70"
                  height="70"
                />
                <img
                  className="hover-img"
                  src="/img/services/style2/hover-img/1.png"
                  alt=""
                  loading="lazy"
                  width="70"
                  height="70"
                />
              </div>
            </div>
            <div className="services-content">
              <div className="services-text">
                <h3 className="title">
                  <a href="software-development.html">
                    Comprehensive Solutions
                  </a>
                </h3>
              </div>
              <div className="services-desc">
                <p>
                  We are able to create a comprehensive solution for the
                  organization of daily operation of a company: web
                  applications, websites, desktop and mobile applications.
                </p>
              </div>
              <div className="serial-number">01</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-20">
          <div className="services-item pink-bg">
            <div className="services-icon">
              <div className="image-part">
                <img
                  className="main-img"
                  src="/img/services/style2/main-img/2.png"
                  alt=""
                  loading="lazy"
                  width="70"
                  height="70"
                />
                <img
                  className="hover-img"
                  src="/img/services/style2/hover-img/2.png"
                  alt=""
                  loading="lazy"
                  width="70"
                  height="70"
                />
              </div>
            </div>
            <div className="services-content">
              <div className="services-text">
                <h3 className="title">
                  <a href="web-development.html">Software Development</a>
                </h3>
              </div>
              <div className="services-desc">
                <p>
                  Software development in the area of trade and production,
                  finance, accounting and warehouse accounting.
                </p>
              </div>
              <div className="serial-number">02</div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="services-item green-bg">
            <div className="services-icon">
              <div className="image-part">
                <img
                  className="main-img"
                  src="/img/services/style3/main-img/9.png"
                  alt=""
                  loading="lazy"
                  width="70"
                  height="70"
                />
                <img
                  className="hover-img"
                  src="/img/services/style3/hover-img/9.png"
                  alt=""
                  loading="lazy"
                  width="70"
                  height="70"
                />
              </div>
            </div>
            <div className="services-content">
              <div className="services-text">
                <h3 className="title">
                  <a href="data-center.html">Mobile Development</a>
                </h3>
              </div>
              <div className="services-desc">
                <p>
                  Applications with a user-friendly interface, accessible and
                  understandable to every user. Applications developed by us
                  will help to optimize any process!
                </p>
              </div>
              <div className="serial-number">03</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="line-inner">
      <div className="line" />
      <div className="line" />
      <div className="line" />
    </div>
  </div>
);
export default ServiceSection;
